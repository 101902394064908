import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { TickCircleOutlineIcon } from "../Icons/TickCircleOutlineIcon";
import { AlertOctagonOutlineIcon } from "../Icons/AlertOctagonOutlineIcon";
import { ShapeCircleIcon } from "../Icons/ShapeCircleIcon";
import { cn } from "@/utils/styles";
import { WarnTriangleIcon } from "../Icons/WarnTriangleIcon";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function (props) {
        const { id, title, description, action, ...rest } = getVariantToastProps(props);

        return (
          <Toast key={id} {...rest}>
            <div className="grid w-full justify-center gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

const getVariantToastProps = ({
  variant,
  title,
  className,
  ...rest
}: ReturnType<typeof useToast>["toasts"][0]) => {
  const Icon = VARIANT_ICON_MAP[(variant || "default") as keyof typeof VARIANT_ICON_MAP];

  return {
    ...rest,
    variant,
    className: cn(className),
    title: (
      <div className="flex flex-row items-center gap-2">
        {Icon && <Icon className="h-[16px] text-white" />}
        <span>{title}</span>
      </div>
    ) as unknown as string
  };
};

const VARIANT_ICON_MAP = {
  default: ShapeCircleIcon,
  success: TickCircleOutlineIcon,
  error: AlertOctagonOutlineIcon,
  warn: WarnTriangleIcon
};
