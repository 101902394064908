import { IconProps } from ".";

export const HeadnodeIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.0223 8.35223V15.6469C21.0223 16.6133 20.5065 17.5067 19.6697 17.99L13.3522 21.6367C12.5153 22.1211 11.4837 22.1211 10.6468 21.6367L4.3294 17.99C3.49253 17.5067 2.97668 16.6133 2.97668 15.6469V8.35223C2.97668 7.38584 3.49253 6.49238 4.3294 6.00919L10.6468 2.3624C11.4837 1.8792 12.5153 1.8792 13.3522 2.3624L19.6697 6.00919C20.5065 6.49238 21.0223 7.38584 21.0223 8.35223Z"
        stroke="white"
        style={{ stroke: "white", strokeOpacity: 1 }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 21.9423V12.0325"
        stroke="white"
        style={{ stroke: "white", strokeOpacity: 1 }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5799 7.0726L11.9978 12.0272L3.41492 7.07153"
        stroke="white"
        style={{ stroke: "white", strokeOpacity: 1 }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
