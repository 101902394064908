import React, { useState } from "react";
import { ACCOUNT_SETTINGS_ROUTES } from "@/utils/mapping";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { cn } from "@/lib/utils";
import { BurgerIcon } from "@/components/Icons/BurgerIcon";
import { CrossIcon } from "@/components/Icons/CrossIcon";
import { Badge } from "@/components/ui/badge";
import { useLocation } from "wouter";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";
import { AuthEventType } from "@/types/auth";
import { LogOutIcon } from "@/components/Icons/LogOutIcon";
import { LinkIcon } from "@/components/Icons/LinkIcon";
import { Button } from "@/components/ui/button";

export const MobileNav: React.FC = () => {
  const [location, setLocation] = useLocation();
  const [open, setOpen] = useState(false);
  const authActorRef = AuthMachineContext.useActorRef();
  const isLoggedIn = AuthMachineContext.useSelector((state) => state.context.isLoggedIn);

  const handleLogout = async () => {
    authActorRef.send({
      type: AuthEventType.LOGOUT
    });
  };

  return (
    <>
      {open && (
        <div
          className="absolute left-0 top-0 z-[2] h-[100vh] w-full bg-black opacity-75"
          onClick={() => setOpen(false)}
        ></div>
      )}
      <HoverCard open={open} openDelay={300}>
        <HoverCardTrigger
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onClick={() => setOpen(!open)}
        >
          <Button
            variant="outline"
            className={cn(
              "relative z-[3] flex h-9 w-9 cursor-pointer items-center rounded-[8px] border bg-gray-dark-1700 p-2.5",
              open ? "border-gray-dark-1200" : "border-gray-dark-900"
            )}
          >
            {open ? (
              <CrossIcon className="pointer-events-none h-4 w-4 text-gray-dark-1000" />
            ) : (
              <BurgerIcon className="h-4 w-4 text-gray-dark-1000" />
            )}
          </Button>
          <HoverCardContent className="mt-4 w-[100%] border-none bg-transparent p-0" align="end">
            <div className="flex w-full flex-col items-center justify-center rounded border border-gray-dark-900 bg-gray-dark-1400 px-6 py-3">
              {ACCOUNT_SETTINGS_ROUTES.map(({ href, disabled, label, requiresLoggin }, index) => {
                if (!isLoggedIn && requiresLoggin) {
                  return null;
                }

                const isActive = location.includes(href);
                return (
                  <div
                    key={`mobile_nav_settings_route-${index}`}
                    onClick={() => {
                      setLocation(href);
                      setOpen(false);
                    }}
                    className={cn(
                      `subtext-small relative w-full whitespace-nowrap border-b-[2px] border-gray-dark-1400 px-3 py-3 text-center
                        hover:text-white hover:before:absolute hover:before:bottom-[5%] hover:before:left-[40%] hover:before:right-[40%]
                        hover:before:top-[50%] hover:before:block hover:before:bg-gray-dark-200 hover:before:opacity-80 hover:before:blur-[12px]`,
                      isActive
                        ? `border-b-2 border-white text-white  before:absolute before:bottom-[5%] before:left-[40%]
                        before:right-[40%] before:top-[50%] before:block before:bg-gray-dark-200 before:opacity-85 before:blur-[12px]`
                        : "text-gray-dark-1000",
                      disabled
                        ? "pointer-events-none flex justify-center gap-4 text-gray-dark-1200"
                        : "cursor-pointer"
                    )}
                  >
                    <span className="relative z-[1]">{label}</span>
                    {disabled && <Badge className="h-6 rounded-[6px] px-2">Coming Soon</Badge>}
                  </div>
                );
              })}
              <a
                href="https://support.io.net"
                target="_blank"
                className="subtext-small flex flex-row items-center gap-2 py-3 text-gray"
              >
                Support Portal
                <LinkIcon className="h-[13px]" />
              </a>
              <a
                href="https://docs.io.net/"
                target="_blank"
                className="subtext-small flex flex-row items-center gap-2 py-3 text-gray"
              >
                Io Documentation
                <LinkIcon className="h-[13px]" />
              </a>
              {isLoggedIn && (
                <a
                  className="subtext-small flex flex-row items-center gap-2 py-3 text-gray"
                  href="/id/funds"
                >
                  Manage Funds
                </a>
              )}
              {isLoggedIn && (
                <div
                  key="mobile_nav_route_logout"
                  onClick={handleLogout}
                  className={cn(
                    "subtext-small flex w-full cursor-pointer justify-center py-3 text-center text-red-light-800"
                  )}
                >
                  <LogOutIcon className="mr-1 h-[18px]" />
                  <span>Sign Out</span>
                </div>
              )}
            </div>
          </HoverCardContent>
        </HoverCardTrigger>
      </HoverCard>
    </>
  );
};
