import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useCookieStore } from "@/store/cookie";
import { cn } from "@/utils/styles";
import { useMemo } from "react";

type CookieAcceptAllModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CookieAcceptAllModal: React.FC<CookieAcceptAllModalProps> = ({ isOpen, onClose }) => {
  const getCookieOptions = useCookieStore((state) => state.getCookieOptions);
  const options = useMemo(() => {
    return getCookieOptions();
  }, [getCookieOptions]);
  const setIsSelectionModalOpen = useCookieStore((state) => state.setIsSelectionModalOpen);
  const setCookiePreferences = useCookieStore((state) => state.setCookiePreferences);

  const handleAcceptAll = () => {
    setCookiePreferences(
      options.map(({ value }) => {
        return value;
      })
    );
    onClose();
  };
  const handleSelect = () => {
    setIsSelectionModalOpen(true);
    onClose();
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogContent showCloseIcon={false} className={cn(COOKIE_DIALOG_POSITION, "p-0")}>
        <DialogHeader className="flex flex-row items-start p-5 pb-0">
          <DialogTitle className="title-h5 flex-1 pl-7 text-center">Cookies</DialogTitle>
        </DialogHeader>
        <div className="subtext-small flex flex-col px-5 text-center text-gray-dark-1000">
          By clicking 'Accept all cookies', you agree to the storing of cookies on your device to
          enhance site navigation, analyse site usage, and assist in our marketing efforts.
        </div>
        <DialogFooter className="items-center gap-3 rounded-b-lg border-t border-gray-dark-1100 bg-black px-5 py-3">
          <Button variant="outline" onClick={handleSelect}>
            Choose Cookies
          </Button>
          <Button onClick={handleAcceptAll}>Accept All Cookies</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const COOKIE_DIALOG_POSITION = cn(
  `top-[calc(100%)] lg:top-[calc(100%-110px)] translate-y-[-100%] data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full`,
  `left-[calc(100%)] lg:left-[calc(100%-30px)] translate-x-[-100%] data-[state=closed]:slide-out-to-top-full data-[state=open]:slide-in-from-top-full`
);
