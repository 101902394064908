import { IconProps } from "@/components/Icons";
import { cn } from "@/utils/styles";
import React from "react";

export type ToggleRowInputProps = {
  value?: boolean;
  title: string;
  content?: React.ReactNode;
  disabled?: boolean;
  name: string;
  onChange: (value: boolean) => void;
  icon: React.FC<IconProps>;
  comingSoon?: boolean;
};

export default function ToggleRowInput({
  onChange,
  value = false,
  disabled,
  title,
  content,
  icon: Icon,
  comingSoon = false
}: ToggleRowInputProps) {
  return (
    <div
      className={cn(
        `
      op flex flex-1 flex-col gap-3 rounded border bg-gray-dark-1700 p-6
      `,
        disabled ? "pointer-events-none opacity-70" : "cursor-pointer hover:border-gray-dark-1300"
      )}
      onClick={() => !disabled && onChange(!value)}
    >
      <div className="flex flex-1 flex-row items-center">
        <div className="h-[32px] w-[32px] rounded bg-gradient-to-b from-[#909090] to-[#2A2A2A] p-[1px]">
          <div className="flex h-full items-center justify-center rounded bg-black">
            <Icon className="h-[20px] w-[20px]" />
          </div>
        </div>
        <div className="subtext-medium mx-4">{title}</div>
        {comingSoon ? (
          <div className="subtext-x-small ml-auto rounded bg-gray-dark-1100 px-2 py-1 text-gray-dark-1000">
            SOON
          </div>
        ) : (
          <div
            className={`ml-auto flex h-[24px] w-[40px] rounded-full border ${value ? "border-blue-dark-400 bg-blue-light-300" : "border-gray-dark-900"}`}
          >
            <div
              className={`h-[22px] w-[22px] rounded-full ${
                value ? "ml-auto bg-black dark:bg-gray-light-1000" : "bg-gray"
              }`}
            ></div>
          </div>
        )}
      </div>
      {content && <div className="subtext-small flex flex-col text-gray-dark-1000">{content}</div>}
    </div>
  );
}
