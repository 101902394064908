import { useMainStore } from "@/store/main";
import { Link, useLocation } from "wouter";
import { slugify } from "@/utils/string";
import { AuthMachineContext } from "@/store/machine/authMachine/authMachine";

export const MainMenu = () => {
  const tool = useMainStore((state) => state.tool);
  const [location] = useLocation();
  const isLoggedIn = AuthMachineContext.useSelector((state) => state.context.isLoggedIn);

  return (
    <div
      data-testid="MainMenu"
      className="flex flex-row overflow-x-auto border-b-[1px] pl-[10px] dark:border-b-gray-dark-500"
    >
      {tool.menuItems?.map((item) => {
        if (tool.value === "id" && !isLoggedIn) {
          return null;
        }

        const { value, label, href, disabled } = item;
        const active = location.slice(0, href?.length) === href;

        return (
          <Link
            href={href}
            key={value}
            data-testid={`MainMenu_${slugify(value)}`}
            className={`group relative mx-1.5 mb-[-1px] box-border flex flex-row items-center gap-2 rounded-t-md border-b-[2px] border-white px-1.5 
              py-2 hover:text-white hover:before:absolute hover:before:bottom-[1px] hover:before:left-[30%] hover:before:right-[30%] hover:before:top-[50%] 
              hover:before:block hover:before:bg-gray-dark-800 hover:before:opacity-80 hover:before:blur-[12px] dark:mb-0 dark:border-transparent 
              ${
                disabled
                  ? `pointer-events-none opacity-20`
                  : active
                    ? `from-gray-light-600 to-gray-light text-black before:absolute before:bottom-[1px] before:left-[30%] before:right-[30%] before:top-[50%]                                                                                      
                       before:block before:bg-gray-dark-800 before:opacity-85 before:blur-[12px] dark:border-b-white dark:bg-gray-dark-600 dark:text-white`
                    : `cursor-pointer border-b-gray-light-500 bg-white text-gray hover:text-black dark:border-b-[3px] dark:bg-gray-dark-600 dark:hover:text-white`
              }`}
          >
            <span className="subtext-small relative z-[1] whitespace-nowrap">{label}</span>
            {disabled && <span className="subtext-small whitespace-nowrap">[soon]</span>}
          </Link>
        );
      })}
    </div>
  );
};
