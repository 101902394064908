import { IconProps } from ".";

export const OpaiBWIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.0155 6.11818H17.9558C17.5545 6.11818 17.2293 6.44131 17.2293 6.83992V8.90262C17.2293 9.30122 17.5545 9.62435 17.9558 9.62435H20.0155C20.4168 9.62435 20.742 9.30122 20.742 8.90262V6.83992C20.742 6.44131 20.4168 6.11818 20.0155 6.11818Z"
        fill="white"
      />
      <path
        d="M3.01728 17.297H0.759382C0.358154 17.297 0.0328932 17.6201 0.0328932 18.0187V20.2783C0.0328932 20.6769 0.358154 21 0.759382 21H3.01728C3.4185 21 3.74376 20.6769 3.74376 20.2783V18.0187C3.74376 17.6201 3.4185 17.297 3.01728 17.297Z"
        fill="white"
      />
      <path
        d="M23.9989 14.2256C23.9163 12.8929 22.8018 11.8226 21.4562 11.7816C20.6471 11.757 19.9207 12.0932 19.4212 12.6427C19.2685 12.8109 19.0538 12.9134 18.8268 12.9134H18.2448C17.7536 12.9134 17.316 12.6099 17.1468 12.1547C16.2263 9.65735 13.8157 7.87351 10.9799 7.87351C8.1441 7.87351 8.26381 8.3164 7.19472 9.06684C6.96769 9.22677 6.65398 9.06684 6.65398 8.78799V6.33982C6.65398 6.08557 6.86037 5.88053 7.11629 5.88053H9.83649C10.5134 5.88053 11.0583 5.33512 11.0583 4.66669V1.21383C11.0583 0.541303 10.5093 0 9.83649 0H6.38567C5.70872 0 5.16385 0.545404 5.16385 1.21383V4.09669C5.16385 4.35093 4.95746 4.55597 4.70154 4.55597H1.45298C0.652189 4.55597 0 5.2039 0 5.99945V9.74757C0 10.5431 0.652189 11.191 1.45298 11.191H4.69741C4.9492 11.191 5.11432 11.4494 5.00699 11.679C4.5777 12.6058 4.36306 13.6433 4.42085 14.7423C4.59834 18.1337 7.41762 20.8443 10.8354 20.9181C13.729 20.9796 16.2098 19.1794 17.1427 16.6369C17.3119 16.1817 17.7494 15.8782 18.2406 15.8782H18.8227C19.0497 15.8782 19.2602 15.9767 19.4129 16.1448C19.8959 16.6779 20.5935 17.0101 21.3695 17.0101C22.8803 17.0101 24.0897 15.747 23.9948 14.2297L23.9989 14.2256ZM10.984 16.7968C9.5558 16.7968 8.3959 15.6445 8.3959 14.2256C8.3959 12.8068 9.5558 11.6544 10.984 11.6544C12.4122 11.6544 13.5721 12.8068 13.5721 14.2256C13.5721 15.6445 12.4122 16.7968 10.984 16.7968Z"
        fill="white"
      />
    </svg>
  );
};
