import { UserNotification } from "@/types";
import { useFormattedDateTime } from "@/hooks/useFormatDateTime";

type NotificationItemType = {
  children?: React.ReactNode;
  notification: UserNotification;
};

export const NotificationItem = ({ notification, children }: NotificationItemType) => {
  const formattedDateTime = useFormattedDateTime();

  return (
    <div className={`relative flex flex-col p-4 dark:bg-transparent`}>
      <div className="mb-2 flex flex-row items-center ">
        {notification.icon ? <notification.icon className="mr-2 h-[16px]" /> : null}
        <span className={`${notification.color} label-x-small uppercase`}>{notification.type}</span>
        {children}
      </div>
      <div className="flex flex-col text-white max-lg:gap-2 lg:flex-row lg:items-end lg:justify-between">
        <div className="subtext-small mr-4 capitalize">{notification.text}</div>
        <div className="subtext-x-small">{formattedDateTime(notification.createdAt)}</div>
      </div>
    </div>
  );
};
