import { IconProps } from ".";

export const IoBWIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.0805 6.66677H2.50224L6.14305 9.1805H17.0805C17.5051 9.1805 17.8546 9.52944 17.8546 9.95809V11.8342L20.3568 13.5617V9.95809C20.3568 8.14873 18.8838 6.66677 17.0805 6.66677Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
      <path
        d="M9.42173 17.3334H24L20.3592 14.8197H9.42173C8.99718 14.8197 8.64768 14.4686 8.64768 14.0421V12.166L6.14542 10.4385V14.0421C6.14542 15.8558 7.61633 17.3334 9.42173 17.3334Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
      <path
        d="M2.50226 10.4384H0V17.3334H2.50226V10.4384Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
      <path
        d="M0 6.66675V8.82075L2.41435 10.488L2.50226 10.4384L2.50224 6.66677L0 6.66675Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
    </svg>
  );
};
