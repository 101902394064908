import { IconProps } from ".";

export const WarnTriangleIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49364 0.621607L11.9236 10.1351C11.9737 10.2228 12 10.3222 12 10.4234C12 10.5246 11.9737 10.6241 11.9236 10.7117C11.8736 10.7994 11.8017 10.8722 11.715 10.9228C11.6283 10.9734 11.5301 11 11.43 11H0.570004C0.469946 11 0.371652 10.9734 0.285 10.9228C0.198348 10.8722 0.126392 10.7994 0.0763644 10.7117C0.0263365 10.6241 -6.35474e-07 10.5246 0 10.4234C6.35497e-07 10.3222 0.0263391 10.2228 0.076368 10.1351L5.50636 0.621607C5.5564 0.533963 5.62835 0.461183 5.715 0.410583C5.80165 0.359982 5.89995 0.333344 6 0.333344C6.10005 0.333344 6.19835 0.359982 6.285 0.410583C6.37165 0.461183 6.4436 0.533963 6.49364 0.621607ZM5.42998 8.11712V9.27028H6.57002V8.11712H5.42998ZM5.42998 4.08107V6.96396H6.57002V4.08107H5.42998Z"
        fill="#F7B955"
      />
    </svg>
  );
};
