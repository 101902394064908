import { executeAPIRequest } from "@/utils/api";
import {
  Device,
  DeviceDetail,
  DeviceDetailResponse,
  DeviceEarningsResponse,
  DeviceEarningsType,
  DeviceHistoricalJob,
  DeviceHistoricalJobResponse,
  DeviceJob,
  DeviceJobResponse,
  DeviceNotification,
  DeviceNotificationResponse,
  DeviceResponse,
  DeviceService,
  DeviceServiceResponse,
  DeviceStats,
  DeviceSummary,
  DeviceSummaryResponse,
  PaginatedResponse,
  DevicePotlType,
  DevicePotlResponseType,
  UserDevicesEarningsResponse,
  UserDevicesEarningsType,
  DevicePowResponseType,
  DevicePowType,
  DeviceBlockEarningsType,
  DeviceBlockEarningsResponseType,
  DevicePowSummaryResponseType,
  DevicePowSummaryType,
  BlockDailyEarnings,
  DeviceStatusCountsType,
  DeviceDailyEarningsType,
  DeviceBlockRewardsChecklistType
} from "@/types";
import { create } from "zustand";
import { useUserStore } from "./user";
import { NvidiaIcon } from "@/components/Icons/NvidiaIcon";
import { PredibaseIcon } from "@/components/Icons/PredibaseIcon";
import { IoIcon } from "@/components/Icons/IoIcon";
import { RayClusterIcon } from "@/components/Icons/RayClusterIcon";
import {
  getHardware,
  getNotification,
  getOperatingSystem,
  getOperatingSystemIcon,
  getSupplier,
  STATUS_COLORS
} from "@/utils/mapping";
import { stringify } from "qs";
import { DEFAULT_PAGINATION_LIMIT } from "@/constants";
import { DeviceStatusType, getDeviceStatusDuration } from "@/utils/device";
import { toStandardisedDuration } from "@/utils/date";
import { BlockRecord, BlockRecordResponse } from "@/types/blockRewards";
import { normalizeBlockRecord } from "./blockRewards";
import { MiddlewareStakeEligibleDevice, UserStakedDevice } from "@/types/staking";

type UseDevicesStoreProps = {
  fetchDevicesRequest: (options: {
    type?: string;
    status: string;
    page?: number;
    limit?: number;
    device_name?: string;
    filters?: {
      [key: string]: string;
    };
    supplierId?: string;
  }) => Promise<{
    resultCount: number;
    results: Device[];
    statuses: string[];
  }>;
  fetchDevices: (options: {
    type?: string;
    status: string;
    page?: number;
    limit?: number;
  }) => Promise<Device[]>;
  fetchDeviceHistoricalJobsRequest: (options: {
    page?: number;
    limit?: number;
    clusterId?: string;
  }) => Promise<{
    resultCount: number;
    results: DeviceHistoricalJob[];
    totalComputeHoursServed: number;
    totalEarned: number;
    totalJobs: number;
    totalSlashed: number;
  }>;
  fetchDeviceEarnings: (options: {
    id: string;
    year?: number;
    date?: string;
    from_date?: string;
    to_date?: string;
  }) => Promise<DeviceEarningsType>;
  fetchEarnings: (options: {
    year?: number;
    date?: string;
    from_date?: string;
    to_date?: string;
  }) => Promise<UserDevicesEarningsType>;
  fetchDevicesBySearchQuery: (options: {
    type?: string;
    searchQuery?: string;
    timestamp?: number;
  }) => Promise<
    {
      id: string;
      name: string;
      url: string;
    }[]
  >;
  fetchDeviceDetail: (options: { id: string; type?: string }) => Promise<DeviceDetail>;
  fetchDeviceSummary: (options: { id: string; type?: string }) => Promise<DeviceSummary>;
  fetchDeviceServices: (options: {
    id: string;
    status: string;
    type?: string;
  }) => Promise<DeviceService[]>;
  fetchDeviceJobsRequest: (options: DeviceJobsRequestOptions) => Promise<{
    results: DeviceJob[];
    resultCount: number;
  }>;
  fetchDeviceNotifications: (deviceId: string) => Promise<DeviceNotification[]>;
  fetchDeviceStats: () => Promise<DeviceStats>;
  fetchDevicePowSummary: (options: {
    id: string;
    type?: string;
  }) => Promise<DevicePowSummaryType | undefined>;
  removeDevice: (id: string) => Promise<void>;
  renameDevice: (id: string, name: string) => Promise<void>;
  pauseDevice: (id: string) => Promise<void>;
  resumeDevice: (id: string) => Promise<void>;
  createId: (recaptchaToken: string) => Promise<string>;
  fetchDeviceProofOfTime: (options: { id: string; page?: number; status?: string }) => Promise<{
    results: DevicePotlType[];
    resultCount: number;
  }>;
  fetchDeviceProofOfWork: (options: { id: string; page?: number; status?: string }) => Promise<{
    results: DevicePowType[];
    resultCount: number;
  }>;
  fetchDeviceBlockRewards: (options: {
    deviceId: string;
    status: string;
    page?: number;
  }) => Promise<{
    results: BlockRecord[];
    resultCount: number;
  }>;
  fetchBlockEarnings: (options: {
    id: string;
    fromDate: string;
    toDate: string;
  }) => Promise<DeviceBlockEarningsType>;
  fetchDeviceProofOfTimeSearch: (options: {
    deviceId: string;
    searchQuery?: string;
  }) => Promise<DevicePotlType[]>;
  fetchDeviceProofOfWorkSearch: (options: {
    deviceId: string;
    searchQuery?: string;
  }) => Promise<DevicePowType[]>;
  fetchDeviceStatusCounts: (options: { deviceId: string }) => Promise<DeviceStatusCountsType>;
  fetchDeviceBlockRewardsChecklist: (options: {
    deviceId: string;
  }) => Promise<DeviceBlockRewardsChecklistType>;
};

type DeviceJobsRequestOptions = {
  id: string;
  type?: string;
  job_id?: string;
  page?: number;
  limit?: number;
};

export const useDeviceStore = create<UseDevicesStoreProps>((_set, get) => ({
  removeDevice: async (id: string) => {
    await executeAPIRequest<{
      data: unknown;
      status: string;
    }>({
      method: "put",
      url: `/io-worker/devices/${id}/terminate`
    });
  },
  renameDevice: async (id, name) => {
    await executeAPIRequest<{
      data: unknown;
      status: string;
    }>({
      method: "put",
      url: `/io-worker/devices/${id}/update-name`,
      options: {
        data: {
          device_name: name
        }
      }
    });
  },
  fetchDevicesBySearchQuery: async ({ type = "worker", searchQuery = "", timestamp = "" }) => {
    try {
      // Temp fix to block search by name on explorer.
      // Considering the device_id length to be 36 restricting the search if it does not meet
      // the length with some leniency.
      if (type === "explorer" && (searchQuery.length < 32 || searchQuery.length > 40)) {
        throw new Error("Please search by the full device id.");
      }

      const response = await executeAPIRequest<{
        data:
          | {
              device_id: string;
              device_name: string;
            }
          | {
              device_id: string;
              device_name: string;
            }[];
        status: string;
      }>({
        method: "get",
        url: `/io-explorer/devices/search?device_identifier=${searchQuery}&timestamp=${timestamp}`
      });

      const { data } = response;

      return (Array.isArray(data) ? data : data.device_id ? [data] : []).map(({ device_id }) => {
        return {
          id: device_id,
          name: device_id,
          url: `/${type}/devices/${device_id}`
        };
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  fetchDevicesRequest: async (options) => {
    const {
      type,
      status = "all",
      page = 1,
      limit = DEFAULT_PAGINATION_LIMIT,
      device_name,
      filters = {},
      supplierId
    } = options;
    const userId = useUserStore.getState().userId;

    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        devices: DeviceResponse[];
        total_devices: number;
        statuses: string[];
      }>;
      status: string;
    }>({
      method: "get",
      url: `${
        type === "explorer"
          ? `/io-explorer/devices/status?${stringify({
              ...(status != "all" ? { status } : {}),
              ...filters,
              page: page,
              supplier_id: supplierId,
              page_size: limit
            })}`
          : `/io-worker/users/${userId}/devices?${stringify({
              ...(status != "all" ? { status } : {}),
              ...filters,
              page: page,
              device_name,
              page_size: limit
            })}`
      }`
    });

    const { data } = response;

    if (typeof data === "string") {
      return {
        resultCount: 0,
        results: [],
        statuses: []
      };
    }

    const resultCount = data.total_devices;

    const results = data.devices.map((item) => {
      const { status } = item;
      const device = normaliseDevice({
        ...item,
        status: status ? status : "up"
      });

      return {
        ...device,
        href: `/${type === "explorer" ? "explorer" : "worker"}/devices/${device.id}`
      };
    });

    return {
      resultCount,
      results,
      statuses: data.statuses
    };
  },
  fetchDevices: async (options) => {
    const { status } = options;
    const { fetchDevicesRequest } = get();

    const response = await fetchDevicesRequest(options);

    const result = response.results.filter((device) => {
      return status === "all" || device.statusValue === status;
    });

    result.sort((a, b) => {
      const c = STATUS_ORDERS.indexOf(a.status);
      const d = STATUS_ORDERS.indexOf(b.status);
      const e = c === -1 ? 100 : c;
      const f = d === -1 ? 100 : d;
      return e - f;
    });

    return result;
  },
  fetchDeviceDetail: async ({ id, type = "worker" }) => {
    const response = await executeAPIRequest<{
      data: DeviceDetailResponse;
      status: string;
    }>({
      method: "get",
      url:
        type === "explorer"
          ? `/io-explorer/devices/${id}/details`
          : `/io-worker/devices/${id}/details`
    });

    return normaliseDeviceDetail(response.data);
  },
  fetchDeviceSummary: async ({ id, type = "worker" }) => {
    const response = await executeAPIRequest<{
      data: DeviceSummaryResponse;
      status: string;
    }>({
      method: "get",
      url:
        type === "explorer"
          ? `/io-explorer/devices/${id}/summary`
          : `/io-worker/devices/${id}/summary`
    });

    return normaliseDeviceSummary(response.data);
  },
  fetchDeviceServices: async ({ id, status, type }) => {
    const response = await executeAPIRequest<{
      data: DeviceServiceResponse | DeviceServiceResponse[];
      status: string;
    }>({
      method: "get",
      url: `${
        type === "explorer"
          ? `/io-explorer/devices/${id}/connected-services`
          : `/io-worker/devices/${id}/connected-services`
      }`
    });

    const { data } = response;
    const services = Array.isArray(data) ? data : [data];
    const serviceStatus = status === "up" ? "connected" : "disconnected";
    return [
      ...(services.length === 1
        ? [
            {
              description: "",
              icon: "https://www.google.com",
              id: 1,
              is_running: true,
              name: "IO Version Control",
              status: "connected"
            },
            {
              description: "",
              icon: "https://www.google.com",
              id: 2,
              is_running: true,
              name: "IO Monitor",
              status: "connected"
            }
          ]
        : []),
      ...services
    ].map((device) => {
      return normaliseDeviceService({
        ...device,
        status: serviceStatus
      });
    });
  },
  fetchDeviceNotifications: async (deviceId: string) => {
    const response = await executeAPIRequest<{
      data: DeviceNotificationResponse[];
      status: string;
    }>({
      method: "get",
      url: `/io-worker/devices/${deviceId}/notifications`
    });

    return response.data.map((device) => {
      return normaliseDeviceNotification(device);
    });
  },
  fetchDeviceJobsRequest: async ({
    job_id = undefined,
    id,
    type,
    page = 1,
    limit: page_size = DEFAULT_PAGINATION_LIMIT
  }: DeviceJobsRequestOptions) => {
    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        total_jobs: number;
        jobs: DeviceJobResponse[];
      }>;
      status: string;
    }>({
      method: "get",
      url: `${
        type === "explorer"
          ? `/io-explorer/devices/${id}/earnings`
          : `/io-worker/devices/${id}/earnings`
      }?${stringify({
        page,
        job_id,
        page_size
      })}`
    });

    const { data } = response;

    return {
      resultCount: data.total_jobs,
      results: data.jobs.map((job) => {
        return normaliseDeviceJob(job);
      })
    };
  },
  fetchDeviceEarnings: async ({ id, from_date, to_date, date }) => {
    const response = await executeAPIRequest<{
      data: DeviceEarningsResponse;
      status: string;
    }>({
      method: "get",
      url: `/io-worker/devices/${id}/earnings-summary-data?${stringify({
        from_date,
        to_date,
        date,
        currency: "iocoin"
      })}`
    });

    return normaliseDeviceEarnings(response.data);
  },
  fetchEarnings: async ({ from_date, to_date, date, year }) => {
    const userId = useUserStore.getState().userId;
    const response = await executeAPIRequest<{
      data: UserDevicesEarningsResponse;
      status: string;
    }>({
      method: "get",
      url: `/io-worker/users/${userId}/earnings-summary-data?${stringify({
        from_date,
        to_date,
        year,
        date,
        currency: "iocoin"
      })}`
    });

    return normailiseUserEarnings(response.data);
  },
  fetchDeviceHistoricalJobsRequest: async ({
    page = 1,
    limit = DEFAULT_PAGINATION_LIMIT,
    clusterId
  }) => {
    const response = await executeAPIRequest<{
      data: PaginatedResponse<{
        jobs: DeviceHistoricalJobResponse[];
        total_earned: number;
        total_slashed: number;
        total_jobs: number;
        total_compute_hours_served: number;
        total_earned_io_coin?: number;
        total_slashed_io_coin?: number;
      }>;
      status: string;
    }>({
      method: "get",
      url: `/io-worker/users/${useUserStore.getState().userId}/earnings-rewards?${stringify({
        page: page,
        page_size: limit,
        cluster_id: clusterId
      })}`
    });

    const { data } = response;

    return {
      resultCount: data.total_jobs,
      results: data.jobs.map((job) => {
        return normaliseDeviceHistoricalJob(job);
      }),
      totalComputeHoursServed: data.total_compute_hours_served,
      totalEarned: data.total_earned,
      totalJobs: data.total_jobs,
      totalSlashed: Math.abs(data.total_slashed),
      totalEarnedIoCoin: data.total_earned_io_coin,
      totalSlashedIoCoin: Math.abs(data.total_slashed_io_coin || 0)
    };
  },
  fetchDeviceStats: async () => {
    const response = await executeAPIRequest<{
      data: DeviceStats;
      status: string;
    }>({
      method: "get",
      url: "/io-explorer/network/info/devices"
    });

    return response.data;
  },
  pauseDevice: async (id: string) => {
    await executeAPIRequest<{
      data: {
        message: string;
        status: string;
      };
      status: string;
    }>({
      method: "put",
      url: `/io-worker/devices/${id}/pause`
    });
  },
  resumeDevice: async (id: string) => {
    await executeAPIRequest<{
      data: {
        message: string;
        status: string;
      };
      status: string;
    }>({
      method: "put",
      url: `/io-worker/devices/${id}/resume`
    });
  },
  createId: async (recaptchaToken) => {
    const response = await executeAPIRequest<{
      data: {
        device_id: string;
        status: string;
      };
      status: string;
    }>({
      method: "get",
      url: `/io-worker/devices/create_device_id`,
      options: {
        headers: {
          recaptchaToken
        }
      }
    });
    return response.data.device_id;
  },
  fetchDeviceProofOfTime: async ({ id, page = 1, status = "all" }) => {
    const response = await executeAPIRequest<
      PaginatedResponse<{
        total_potl: number;
        potl: DevicePotlResponseType[];
      }>
    >({
      method: "get",
      url: `/io-blocks/devices/${id}/potl/${status}/${page}`
    });

    return {
      resultCount: response.total_potl,
      results: response.potl.map((item) => normaliseDevicePotl(item))
    };
  },
  fetchDeviceProofOfTimeSearch: async ({ deviceId, searchQuery }) => {
    const response = await executeAPIRequest<DevicePotlResponseType[]>({
      method: "get",
      url: `/io-blocks/devices/${deviceId}/potl/${searchQuery}`
    });

    return response.map((item) => normaliseDevicePotl(item));
  },
  fetchDeviceProofOfWork: async ({ id, page = 1, status = "all" }) => {
    const response = await executeAPIRequest<
      PaginatedResponse<{
        total_pow: number;
        pow: DevicePowResponseType[];
        page: number;
        page_size: number;
      }>
    >({
      method: "get",
      url: `/io-blocks/devices/${id}/pow/${status}/${page}`
    });

    return {
      resultCount: response.total_pow,
      results: response.pow.map((item) => normaliseDevicePow(item))
    };
  },
  fetchDeviceProofOfWorkSearch: async ({ deviceId, searchQuery }) => {
    const response = await executeAPIRequest<DevicePowResponseType[]>({
      method: "get",
      url: `/io-blocks/devices/${deviceId}/pow/${searchQuery}`
    });

    return response.map((item) => normaliseDevicePow(item));
  },
  fetchDeviceBlockRewards: async ({ page = 1, status, deviceId }) => {
    const response = await executeAPIRequest<
      PaginatedResponse<{
        page: number;
        page_size: number;
        total_rewards: number;
        block_rewards: BlockRecordResponse[];
      }>
    >({
      method: "get",
      url: `/io-blocks/devices/${deviceId}/blockRewards/${status}/${page}`
    });

    return {
      resultCount: response.total_rewards,
      results: response.block_rewards.map((item) => {
        return normalizeBlockRecord(item);
      })
    };
  },
  fetchBlockEarnings: async ({ id, fromDate, toDate }) => {
    const response = await executeAPIRequest<DeviceBlockEarningsResponseType>({
      method: "get",
      url: `/io-blocks/devices/${id}/block-rewards-summary/${fromDate}/${toDate}`
    });

    return normaliseDeviceBlockEarnings(response);
  },
  fetchDevicePowSummary: async ({ id, type = "worker" }) => {
    const response = await executeAPIRequest<{
      data: DevicePowSummaryResponseType;
      status: string;
    }>({
      method: "get",
      url:
        type === "explorer"
          ? `/io-explorer/devices/${id}/pow-summary`
          : `/io-worker/devices/${id}/pow-summary`
    });

    return normaliseDevicePowSummary(response.data);
  },
  fetchDeviceStatusCounts: async ({ deviceId }) => {
    const response = executeAPIRequest<DeviceStatusCountsType>({
      method: "get",
      url: `/io-blocks/devices/${deviceId}/counts `
    });

    return response;
  },
  fetchDeviceBlockRewardsChecklist: async ({ deviceId }) => {
    const response = executeAPIRequest<DeviceBlockRewardsChecklistType>({
      method: "get",
      url: `/io-blocks/devices/${deviceId}/ready`
    });

    return response;
  }
}));

const normaliseDevice = (result: DeviceResponse) => {
  const statusType = result.status.toLowerCase();
  const status = STATUS_MAP[`${statusType}` as keyof typeof STATUS_MAP];
  const hardware = getHardware(result.brand_name);
  const id = `${result.device_id}`;

  const newResult = {
    id,
    name: result.device_name,
    hardwareName: result.hardware || result.hardware_name,
    hardwareQuantity: result.hardware_quantity,
    operatingSystem: result.operating_system,
    isWorking: result.is_working,
    upFor: toStandardisedDuration(result.status_duration),
    isChecked: result.last_audit_successful,
    isVerified: result.last_challenge_successful,
    readinessInfo: result.readiness_info,
    blockRewardsReady: result.block_rewards_ready,
    ...status,
    ...hardware
  } as Device;

  return {
    ...newResult,
    statusDuration: getDeviceStatusDuration(newResult, result.status_duration)
  };
};

export const STATUS_MAP = {
  up: {
    status: DeviceStatusType.UP,
    statusLabel: "Running",
    statusColor: STATUS_COLORS["up"].colorClass,
    statusValue: "up",
    networkMapLabel: "Active for:"
  },
  paused: {
    status: DeviceStatusType.PAUSED,
    statusLabel: "Paused",
    statusColor: STATUS_COLORS["paused"].colorClass,
    statusValue: "paused",
    networkMapLabel: "Paused"
  },
  inactive: {
    status: DeviceStatusType.INACTIVE,
    statusLabel: "Inactive",
    statusColor: STATUS_COLORS["inactive"].colorClass,
    statusValue: "inactive",
    networkMapLabel: "Inactive"
  },
  down: {
    status: DeviceStatusType.DOWN,
    statusLabel: "Offline",
    statusColor: STATUS_COLORS["down"].colorClass,
    statusValue: "down",
    networkMapLabel: "Down"
  },
  terminated: {
    status: DeviceStatusType.TERMINATED,
    statusLabel: "Terminated",
    statusColor: STATUS_COLORS["terminated"].colorClass,
    statusValue: "terminated",
    networkMapLabel: "Terminated"
  },
  unsupported: {
    status: DeviceStatusType.UNSUPPORTED,
    statusLabel: "Unsupported",
    statusColor: STATUS_COLORS["unsupported"].colorClass,
    statusValue: "unsupported",
    networkMapLabel: "Unsupported"
  },
  blocked: {
    status: DeviceStatusType.BLOCKED,
    statusLabel: "Blocked",
    statusColor: STATUS_COLORS["blocked"].colorClass,
    statusValue: "blocked",
    networkMapLabel: "Blocked"
  },
  "restart required": {
    status: DeviceStatusType.RESTART_REQUIRED,
    statusLabel: "Restart required",
    statusColor: STATUS_COLORS["restart required"].colorClass,
    statusValue: "restart required",
    networkMapLabel: "Restart required"
  },
  destroyed: {
    status: DeviceStatusType.DESTROYED,
    statusLabel: "Destroyed",
    statusColor: STATUS_COLORS["destroyed"].colorClass,
    statusValue: "destroyed",
    networkMapLabel: "Destroyed"
  },
  maintenance: {
    status: DeviceStatusType.DESTROYED,
    statusLabel: "Maintenance",
    statusColor: STATUS_COLORS["maintenance"].colorClass,
    statusValue: "maintenance",
    networkMapLabel: "In Maintenance"
  },
  pending: {
    status: DeviceStatusType.PENDING,
    statusLabel: "Pending",
    statusColor: STATUS_COLORS["pending"].colorClass,
    statusValue: "pending",
    networkMapLabel: "Pending"
  },
  idle: {
    status: DeviceStatusType.IDLE,
    statusLabel: "Waiting",
    statusColor: STATUS_COLORS["idle"].colorClass,
    statusValue: "idle",
    networkMapLabel: "Waiting"
  }
};

const normaliseDeviceDetail = (result: DeviceDetailResponse) => {
  const hardware = getHardware(result.brand_name);
  const supplierName = result.supplier?.name || result.supplier_name || "";
  const supplier = getSupplier(supplierName);
  const downTimePercentage =
    typeof result.down_percentage === "number" ? result.down_percentage : null;
  const operatingSystem = getOperatingSystem(result.operating_system);

  return {
    id: `${result.device_id}`,
    status: result.status,
    createdAt: result.start_date,
    brandIcon: result.brand_icon,
    downPercentage: downTimePercentage,
    upPercentage: typeof downTimePercentage === "number" ? 100 - downTimePercentage : null,
    downtimeByDate:
      typeof result.down_percentage === "number" && result.downtime_by_date
        ? Object.fromEntries(
            Object.entries(result.downtime_by_date).map(({ 0: key, 1: { downtime, note } }) => {
              return [key, { downtime, note }];
            })
          )
        : null,
    hardwareName: result.hardware_name,
    hardwareQuantity: result.hardware_quantity,
    locationIcon: result.location_icon,
    locationName: result.location_name,
    locationCode: result.iso2,
    securitySoc2: result.security_soc2,
    connectivityTierName: result.base_tier_name,
    rayVersion: result.ray_version,
    pythonVersion: result.python_version,
    deviceType: result.device_type,
    operatingSystem,
    operatingSystemIcon: getOperatingSystemIcon(operatingSystem),
    readinessInfo: result.readiness_info,
    ...(supplier
      ? {
          supplierName,
          supplierIcon: supplier.icon
        }
      : {}),
    ...hardware
  } as DeviceDetail;
};

const normaliseDeviceService = (result: DeviceServiceResponse) => {
  const service = SERVICE_MAP[`${result.name.toLowerCase()}` as keyof typeof SERVICE_MAP];

  return {
    id: `${result.id}`,
    description: result.description,
    isRunning: result.is_running,
    name: result.name,
    status: result.status,
    ...service
  } as DeviceService;
};

const SERVICE_MAP: {
  [k: string]: {
    icon: typeof NvidiaIcon;
  };
} = {
  "ray app": {
    icon: RayClusterIcon
  },
  "pytorch fsdp": {
    icon: PredibaseIcon
  },
  "io native app": {
    icon: IoIcon
  },
  "io monitor": {
    icon: IoIcon
  },
  "ray.io": {
    icon: RayClusterIcon
  },
  "io version control": {
    icon: IoIcon
  }
};

const normaliseDeviceHistoricalJob = (result: DeviceHistoricalJobResponse) => {
  const resourceId = result.for_id;
  const hardware = getHardware(result.brand_name);

  return {
    id: resourceId,
    forId: result.for_id,
    deviceId: result.device_id,
    status: result.status,
    startTime: result.start_time,
    endTime: result.end_time,
    totalHireRate: result.total_hire_rate,
    earned: result.earned,
    slashed: Math.abs(result.slashed),
    computeHoursServed: result.compute_hours_served,
    computeHoursHired: result.compute_hours_hired,
    hardwareName: result.hardware_name,
    uptimePercent: 0,
    currency: result.currency,
    verificationJob: !!result.verification_job,
    ...hardware
  } as DeviceHistoricalJob;
};

const normaliseDeviceJob = (result: DeviceJobResponse) => {
  const forId = result.for_id || result.for;
  const resourceId = result.resource_id || result.id || forId;

  return {
    id: resourceId,
    resourceId: resourceId,
    computeHoursHired: result.compute_hours_hired,
    computeHoursServed: result.compute_hours_served,
    createdAt: result.created_at,
    deviceId: result.device_id,
    forId,
    earned: result.earned,
    earningClaimTxidUrl: result.earning_claim_txid_url,
    endTime: result.end_time,
    slashed: Math.abs(result.slashed),
    startTime: result.start_time,
    status: result.status,
    totalHireRate: result.total_hire_rate,
    updatedAt: result.updated_at,
    currency: result.currency,
    uptimePercent: typeof result.uptime_percent === "number" ? result.uptime_percent : 0,
    verificationJob: !!result.verification_job
  } as DeviceJob;
};

const normaliseDeviceNotification = (result: DeviceNotificationResponse) => {
  const notification = getNotification(result.type);

  return {
    createdAt: result.created_at,
    deviceId: result.device_id,
    id: `${result.id}`,
    text: result.text,
    backgroundColor: notification.color,
    ...notification
  } as DeviceNotification;
};

const normaliseDeviceSummary = (result: DeviceSummaryResponse) => {
  const statusType = (result.status || "up").toLowerCase();
  const status = STATUS_MAP[`${statusType}` as keyof typeof STATUS_MAP];
  const downTimePercentage =
    typeof result.down_percentage === "number" ? result.down_percentage : 0;

  const newResult = {
    name: result.device_name,
    connectivityTier: result.connectivity_tier,
    deviceId: result.device_id,
    downPercentage: downTimePercentage,
    downloadSpeedMbps: result.download_speed_mbps,
    downtimeByDate: Object.fromEntries(
      Object.entries(result.downtime_by_date || {}).map(({ 0: key, 1: { downtime, note } }) => {
        return [key, { downtime, note }];
      })
    ),
    totalComputeHoursServed: result.total_compute_hours_served,
    totalEarnings: result.total_earnings,
    totalJobs: result.total_jobs,
    totalSlashedEarning: Math.abs(result.total_slashed_earning || 0),
    uploadSpeedMbps: result.upload_speed_mbps,
    isWorking: result.is_working,
    statusDuration: null,
    isVerified: result.last_challenge_successful,
    isChecked: result.last_audit_successful,
    ...status
  } as DeviceSummary;
  return {
    ...newResult,
    totalEarnedIoCoin: result.total_earnings_io_coin,
    totalSlashedIoCoin: Math.abs(result.total_slahed_io_coin || 0),
    statusDuration: getDeviceStatusDuration(newResult, result.status_duration)
  };
};

const normaliseDevicePowSummary = (response: DevicePowSummaryResponseType) => {
  return {
    deviceId: response.device_id,
    lastChallengeStatus: response.last_challenge_status,
    powJobsPassed: response.pow_jobs_passed,
    powJobsFailed: response.pow_jobs_failed
  } as DevicePowSummaryType;
};

const normaliseDevicePow = (pow: DevicePowResponseType) => {
  return {
    proofId: pow.proof_id,
    blockId: pow.block_id,
    deviceId: pow.device_id,
    status: pow.status,
    timeAndDate: pow.time_and_date,
    complexity: pow.complexity,
    hashRate: pow.hash_rate,
    computeTime: pow.compute_time,
    txidUrl: pow.txid_url
  } as DevicePowType;
};

const normaliseDevicePotl = (potl: DevicePotlResponseType) => {
  return {
    blockId: potl.block_id,
    deviceId: potl.device_id,
    status: potl.status,
    timeAndDate: potl.time_and_date,
    uptimePercentage: potl.uptime_percentage,
    uptimeInMinutes: potl.uptime_in_minutes,
    txidUrl: potl.txid_url
  } as DevicePotlType;
};

const normaliseDeviceBlockEarnings = (data: DeviceBlockEarningsResponseType) => {
  return {
    deviceId: data.device_id,
    totalBlockRewards: data.total_block_rewards,
    totalBlocksEarned: data.total_blocks_earned,
    totalBlocksFailed: data.total_blocks_failed,
    totalBlocksMissed: data.total_blocks_missed,
    datewiseEarningsSummary: data.datewise_earnings_summary.map((earning) => {
      return {
        rewards: earning.rewards,
        dateWithYear: earning.date_with_year
      } as BlockDailyEarnings;
    })
  } as DeviceBlockEarningsType;
};

const normaliseDeviceEarnings = (data: DeviceEarningsResponse) => {
  return {
    deviceId: data.device_id,
    totalComputeHours: data.total_compute_hours,
    totalEarnings: data.total_earnings,
    totalJobs: data.total_jobs,
    totalSlashed: data.total_slashed,
    datewiseEarningsSummary: data.datewise_earnings_summary.map((earning) => {
      return {
        earning: earning.earning,
        dateWithYear: earning.date_with_year
      } as DeviceDailyEarningsType;
    })
  } as DeviceEarningsType;
};

const normailiseUserEarnings = (data: UserDevicesEarningsResponse) => {
  return {
    userId: data.user_id,
    totalComputeHours: data.total_compute_hours,
    totalEarnings: data.total_earnings,
    totalJobs: data.total_jobs,
    totalSlashed: data.total_slashed,
    year: data.year,
    totalEarnedIoCoin: data.total_earned_io_coin,
    totalSlashedIoCoin: data.total_slashed_io_coin,
    dailyEarningsSummary: data.daily_earnings_summary.map((earning) => {
      return {
        earning: earning.earning,
        dateWithYear: earning.date_with_year
      } as DeviceDailyEarningsType;
    })
  } as UserDevicesEarningsType;
};

export const STATUS_ORDERS = [
  "running",
  "paused",
  "failed",
  "terminated",
  "unpaid",
  "failed",
  "termination requested",
  "terminated",
  "destroyed"
];

export const toStakeEligibleDevice = (device: UserStakedDevice) => {
  return {
    createDate: "",
    deviceId: device.id,
    deviceName: device.deviceName,
    id: device.id,
    lastRewardsTime: "",
    minimumStakeAmount: device.minimumStaked,
    totalRewards: 0,
    totalStakeAccountCount: 1,
    totalStakeAmount: device.amountStaked,
    updateDate: ""
  } as MiddlewareStakeEligibleDevice;
};
