import { IconProps } from ".";
export const RenderNetworkBWIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.30909C17.904 1.30909 22.6909 6.096 22.6909 12C22.6909 17.904 17.904 22.6909 12 22.6909C6.09601 22.6909 1.3091 17.904 1.3091 12C1.3091 9.88145 1.92438 7.90909 2.98692 6.24764C3.49316 6.52666 4.08309 6.6136 4.64829 6.49248C5.2135 6.37136 5.716 6.05032 6.06345 5.58836C6.4109 5.1264 6.57995 4.55456 6.53953 3.97794C6.49911 3.40131 6.25194 2.85865 5.84344 2.44968C5.43494 2.04071 4.89257 1.79291 4.31599 1.75183C3.73942 1.71074 3.16738 1.87913 2.70502 2.22605C2.24266 2.57296 1.92104 3.0751 1.79927 3.64016C1.6775 4.20523 1.76376 4.79526 2.04219 5.30182C0.707753 7.28047 -0.0035313 9.61342 1.31829e-05 12C1.31829e-05 18.6273 5.37274 24 12 24C18.6273 24 24 18.6273 24 12C24 5.37273 18.6273 0 12 0V1.30909ZM4.14547 3.05455C3.85614 3.05455 3.57866 3.16948 3.37408 3.37407C3.16949 3.57865 3.05456 3.85613 3.05456 4.14545C3.05456 4.43478 3.16949 4.71226 3.37408 4.91684C3.57866 5.12143 3.85614 5.23636 4.14547 5.23636C4.43479 5.23636 4.71227 5.12143 4.91685 4.91684C5.12144 4.71226 5.23637 4.43478 5.23637 4.14545C5.23637 3.85613 5.12144 3.57865 4.91685 3.37407C4.71227 3.16948 4.43479 3.05455 4.14547 3.05455Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
      <path
        d="M17.2363 11.9999C17.2363 13.3887 16.6846 14.7206 15.7026 15.7026C14.7206 16.6846 13.3887 17.2363 11.9999 17.2363C10.6112 17.2363 9.27928 16.6846 8.29727 15.7026C7.31527 14.7206 6.76358 13.3887 6.76358 11.9999C6.76358 10.6112 7.31527 9.27929 8.29727 8.29728C9.27928 7.31527 10.6112 6.76358 11.9999 6.76358C13.3887 6.76358 14.7206 7.31527 15.7026 8.29728C16.6846 9.27929 17.2363 10.6112 17.2363 11.9999Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
    </svg>
  );
};
