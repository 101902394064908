import { IconProps } from "@/components/Icons";
import { TickThickIcon } from "@/components/Icons/TickThickIcon";
import React from "react";

type OptionType = {
  label: string;
  value: string;
  icon?: React.FC<IconProps>;
  disabled?: boolean;
  description?: string;
  component?: React.ReactNode;
};

export type CheckboxCardInputProps = {
  value?: string[];
  options: OptionType[];
  disabled?: boolean;
  name: string;
  onChange: (value: string[]) => void;
  className?: string;
  singleSelection?: boolean;
};

export default function CheckboxCardInput({
  onChange,
  value = [],
  options,
  className
}: CheckboxCardInputProps) {
  const handleOnClick = (item: OptionType) => {
    if (item.disabled) {
      return;
    }
    if (value.includes(item.value)) {
      return onChange(value.filter((val) => val != item.value));
    }

    return onChange([...value, item.value]);
  };
  return (
    <div className={`grid grid-cols-1 gap-2 ${className}`}>
      {options.map((option) => {
        const isActive = value.includes(option.value);
        return (
          <div
            key={option.value}
            onClick={() => handleOnClick(option)}
            className={`
          ${isActive ? "border-gray-dark-1300" : "border-gray-800"}
          ${option.disabled ? "opacity-50" : "cursor-pointer hover:border-gray-dark-1300"}
          flex flex-row justify-between gap-4 rounded border bg-gray-dark-1700 px-4 py-3
          `}
          >
            <div className="flex items-center justify-between">
              <div
                className={`mr-4 flex h-[24px] w-[24px] items-center justify-center overflow-hidden rounded-md border ${
                  isActive
                    ? "border-black bg-black dark:border-gray-light-1000 dark:bg-gray-light-1000"
                    : "border-gray-dark-1200"
                }`}
              >
                {isActive && (
                  <div className="flex h-full w-full items-center justify-center ">
                    <TickThickIcon className="w-[12px] invert" />
                  </div>
                )}
              </div>
              {option.icon && <option.icon className="h-[24px] w-[24px]" />}
              <div className="mr-4 flex-1">
                <div className="subtext-medium">{option.label}</div>
                {option.description ? (
                  <div className="subtext-x-small mt-2 text-gray">{option.description}</div>
                ) : null}
              </div>
            </div>
            {option.component}
          </div>
        );
      })}
    </div>
  );
}
