import { IconProps } from ".";

export const AethirBWIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.5328 2.56348H9.46675C8.88317 2.56348 8.3515 2.89709 8.09834 3.42153L5.50832 8.78734C5.18426 9.45841 5.67416 10.2368 6.42102 10.2368H9.91616C10.4022 10.2368 10.8453 9.9588 11.0567 9.52155L11.9998 7.56783L16.8317 17.5766L10.3035 12.8604C10.044 12.6733 9.73261 12.5722 9.41234 12.5722H4.6336C4.05003 12.5722 3.51836 12.9058 3.26513 13.4303L0.101677 19.9878C-0.222381 20.6588 0.267522 21.4373 1.01438 21.4373H4.50952C4.99564 21.4373 5.43871 21.1592 5.6501 20.722L8.21857 15.4017L15.2949 20.7763C15.8595 21.2047 16.5481 21.4373 17.257 21.4373H22.9864C23.7333 21.4373 24.2232 20.6588 23.8991 19.9878L15.9013 3.42153C15.6481 2.89709 15.1164 2.56348 14.5328 2.56348Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
    </svg>
  );
};
