export const timeStringToNumber = (timeString: string | undefined) => {
  const parts = (timeString || "").toLowerCase().split("hrs");

  if (parts.length === 2) {
    return parseInt(parts[0], 10);
  }

  return undefined;
};

export const toNumber = (value: number | undefined) => {
  if (value === undefined) {
    return 0;
  }
  return isNaN(value) ? 0 : value;
};

export const toBytes = (bytes: number, decimalPlaces: number): string => {
  if (typeof bytes !== "number" || isNaN(bytes)) {
    return "-";
  }

  const units = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte"];
  const unitIndex = Math.max(
    0,
    Math.min(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), units.length - 1)
  );

  try {
    const result = Intl.NumberFormat("en-US", {
      style: "unit",
      unit: bytes === 0 ? "megabyte" : units[unitIndex],
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces + 1
    }).format(bytes / 1024 ** unitIndex);

    return result;
  } catch (e) {
    return `${(bytes / 1024 ** unitIndex).toFixed(decimalPlaces)} M`;
  }
};

export const toLocaleString = (value: unknown, decimalPlaces?: number) => {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value !== "number") {
    return "-";
  }

  const options = {
    ...(typeof decimalPlaces === "number"
      ? {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces
        }
      : {})
  } as Intl.NumberFormatOptions;

  return value.toLocaleString("en-US", options);
};

export const toLocaleCurrencyString = (
  value: unknown,
  currency: string,
  decimalPlaces?: number
) => {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value !== "number") {
    return "-";
  }

  return `${currency}${toLocaleString(value, decimalPlaces)}`;
};

export const toBits = (bits: number, decimalPlaces: number): string => {
  if (typeof bits !== "number" || isNaN(bits)) {
    return "-";
  }

  const units = ["bit", "kilobit", "megabit", "gigabit", "terabit"];
  const unitIndex = Math.max(
    0,
    Math.min(Math.floor(Math.log(Math.abs(bits)) / Math.log(1024)), units.length - 1)
  );

  try {
    const result = Intl.NumberFormat("en-US", {
      style: "unit",
      unit: bits === 0 ? "megabit" : units[unitIndex],
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces + 1
    }).format(bits / 1024 ** unitIndex);

    return result;
  } catch (e) {
    return `${(bits / 1024 ** unitIndex).toFixed(decimalPlaces)} M`;
  }
};

export const isNumericOr = (value: unknown, orNumber: number) => {
  const val = Number(value);
  return val && !isNaN(val) ? val : orNumber;
};
