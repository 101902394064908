import { IconProps } from ".";

export const FileCoinCircleBWIcon: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 19.9001C0 31.0001 9 40.0001 20 40.0001C31 40.0001 39.9 31.1001 40 20.2001C40 9.0001 31.1 0.100095 20.1 9.45406e-05C9 -0.0999055 0.1 8.90009 0 19.9001ZM21.3 20.8001L21.9 17.6001L27.2 18.4001L27.6 16.9001L22.1 16.1001C22.4274 15.071 22.7111 14.0419 22.992 13.0231C23.3115 11.8638 23.6274 10.7178 24 9.60009C24.3 8.90009 24.8 8.20009 25.3 7.70009C26 7.00009 26.9 7.10009 27.5 7.90009C27.65 8.05009 27.775 8.2001 27.9 8.3501C28.025 8.5001 28.15 8.65009 28.3 8.80009C28.7 9.2001 29.2 9.30009 29.6 9.00009C29.9 8.70009 29.9 8.20009 29.9 7.80009C29.9 7.60009 29.7 7.40009 29.6 7.30009C29 6.60009 28.1 6.30009 27.2 6.30009C25.1 6.20009 23.7 7.20009 22.5 8.80009C21.3 10.4001 20.8 12.2001 20.3 14.0001C20.1835 14.2914 20.1009 14.5827 20.0127 14.8937C19.9495 15.1166 19.8835 15.3496 19.8 15.6001L13.9 14.8001L13.7 16.4001L19.4 17.3001L18.7 20.4001L13.2 19.6001L13 21.1001L18.3 21.9001V22.3001C17.8837 24.2982 17.3981 26.2271 16.9009 28.202C16.8009 28.5992 16.7005 28.9982 16.6 29.4001C16.3 30.5001 15.8 31.6001 14.9 32.4001C14.1 33.0001 13.3 32.9001 12.7 32.1001C12.5 31.7001 12.2 31.3001 11.9 31.0001C11.6 30.9001 11.1 30.8001 10.9 30.9001C10.6 31.1001 10.4 31.6001 10.4 32.0001C10.4 32.3001 10.7 32.7001 11 32.9001C11.9 33.6001 13.1 33.7001 14.2 33.5001C16.1 33.2001 17.5 32.0001 18.3 30.3001C18.9 29.0001 19.4 27.6001 19.9 26.2001C20.2042 25.47 20.3974 24.6659 20.5921 23.8553C20.7174 23.3336 20.8434 22.8092 21 22.3001L26.6 23.1001L27 21.6001L21.3 20.8001Z"
        fill="white"
        style={{ fill: "white", fillOpacity: 1 }}
      />
    </svg>
  );
};
