import { CrossIcon } from "@/components/Icons/CrossIcon";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog";
import React from "react";

type AlertModalProps = {
  isOpen: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
  submitLabel: string;
  onClose: () => void;
};

export const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  title,
  description,
  submitLabel,
  onClose
}) => {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="p-0">
        <AlertDialogHeader className="flex flex-row items-start p-5 pb-0">
          <AlertDialogTitle className="title-h5 flex-1 pl-7 text-center">
            {title === "ERROR" ? "io.net" : title}
          </AlertDialogTitle>
          <CrossIcon
            onClick={onClose}
            className="!mt-0 h-[24px] cursor-pointer text-gray-dark-1000 transition-all hover:text-white"
          />
        </AlertDialogHeader>
        <div className="subtext-small flex flex-col px-5 text-center text-gray-dark-1000">
          {description}
        </div>
        <AlertDialogFooter className="items-center rounded-b-lg border-t border-gray-dark-1100 bg-black px-5 py-3">
          <AlertDialogAction
            onClick={onClose}
            className="border border-gray-dark-900 bg-gray-dark-600 text-white transition-all hover:bg-white hover:text-black"
          >
            {submitLabel}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  // return (
  //   <Modal isOpen={isOpen} onClose={onClose}>
  //     <Dialog.Panel
  //       data-testid="AlertModal"
  //       className="relative flex w-[650px] transform flex-col gap-8 overflow-hidden rounded-lg bg-white  p-8 text-left shadow-xl transition-all dark:bg-black
  //     "
  //     >
  //       <>
  //         <div className="flex flex-row items-center">
  //           <h2 className="label-medium text-black dark:text-white">{title}</h2>
  //           <div className="flex-1"></div>
  //           <CrossIcon className="h-[13px] cursor-pointer text-black" onClick={onClose} />
  //         </div>
  //         <p className="subtext-x-small">{description}</p>

  //         <div className="border-t border-gray-light-100 dark:border-gray-dark-400"></div>
  //         <div className="flex flex-row items-center">
  //           <div className="flex-1"></div>
  //           <PrimaryButton onClick={onClose}>{submitLabel}</PrimaryButton>
  //         </div>
  //       </>
  //     </Dialog.Panel>
  //   </Modal>
  // );
};
